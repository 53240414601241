<template>
	<phi-page :loading="$store.state.api.isLoading" class="state-phi-settings">
		<div slot="toolbar">
			<mu-icon-button @click="$router.go(-1)" icon="arrow_back"></mu-icon-button>
			<h1>{{ $t('noun.settings') }}</h1>
		</div>

		<div>
			<section class="notifications">
				<div class="phi-card">
					<router-link to="/settings/notifications" class="phi-media">
						<mu-icon value="inbox" class="phi-media-figure" />
						<span class="phi-media-body">{{ $t("noun.notifications") }}</span>
						<mu-icon value="keyboard_arrow_right" class="phi-media-right" />
					</router-link>
				</div>
			</section>

			<section class="language">
				<h2>{{ $t('noun.language') }}</h2>
				<div class="phi-card">
					<div class="phi-media"
						v-for="(languageName, languageCode) in $store.state.i18n.available"
						:key="languageCode"
						:class="{selected: $store.getters.isCurrentLanguage(languageCode)}"
						@click="setLanguage(languageCode)"
					>
						<span class="phi-media-body">{{ languageName }}</span>
						<mu-icon value="check" class="checkmark phi-media-right"></mu-icon>
					</div>
				</div>
			</section>

			<section class="general">
				<h2>{{ $t('noun.general') }}</h2>
				<div class="phi-card">
					<div class="phi-media logout" @click="logout()">
						<mu-icon class="phi-media-figure" value="power_settings_new"></mu-icon>
						<div class="phi-media-body">{{ $t('action.logout') }}</div>
					</div>
				</div>
			</section>
		</div>
	</phi-page>
</template>

<script>
export default {
	name: "settings",

	methods: {
		setLanguage(language) {
			this.$store.dispatch("setLanguage", language);
		},

		logout() {
			this.$store.commit("logout");
			this.$router.push("login");
		}
	}
}
</script>


<style lang="scss">
.state-phi-settings {
    a {
        .phi-media-right {
            opacity: .5;
        }
    }

	section {
		margin-bottom: 2em;

		h2 {
			font-weight: 1em;
			text-transform: uppercase;
			color: #666;
			margin-bottom: 0.5em;
			font-size: 0.8em;
		}

		.phi-media {
			cursor: pointer;
			&:hover {
				background-color: rgba(0,0,0,.04);
			}

			.phi-media-body {
				align-self: center;
			}

			.phi-media-figure {
				align-self: center;
				text-align: center;
			}
		}

		&.language {
			.phi-media {
				.checkmark {
					display: none;
				}

				&.selected {
					font-weight: bold;
					.checkmark {
						display: block;
					}
				}
			}
		}

		.logout {
			color: #df3e3e;
		}
	}
}
</style>